import React from "react";
import flower from "../../assets/SVGs/flower.svg";
import dayjs from "dayjs";

const Trademark = () => {
  return (
    <div className="flex text-2xs sm:text-xs text-white">
      <div className="">
        <div className="flex flex-row justify-between">
          <div>Benchmarker Shop Portal</div>
          <div>v{process.env.REACT_APP_VERSION}</div>
        </div>
        <div className="flex flex-row text-3xs sm:text-2xs pt-4 gap-x-1 sm:gap-x-2">
          <img
            src={flower}
            alt="SenSys"
            className="sm:w-4 sm:h-4 w-3 h-3 text-white"
          />
          <a href="https://www.sensys.co.nz" className="text-teal-500">
            SenSys
          </a>
          <span className="text-white text-2xs sm:text-xs">&#169;</span>
          <div>{dayjs().format("YYYY")} Design Electronics Limited</div>
        </div>
      </div>
    </div>
  );
};

export default Trademark;
